import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { addToCart } from "../../store/actions/action";
import Product from "./product";
import api from "../../api";
import ProductTabs from "./alltab";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";

const ProductSinglePage = (props) => {
  const { slug, id } = useParams(); // Add `id` to params

  const productsArray = api();
  const { addToCart } = props;
  const [product, setProduct] = useState(null);

  // Fetch the product whenever `id` or `slug` changes
  useEffect(() => {
    const foundProduct = productsArray.find(
      (product) => product.id === parseInt(id, 10) // Match by `id`
    );
    setProduct(foundProduct || null); // If no product matches, set to null
  }, [id, slug, productsArray]);

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle={"Product Details"} pagesub={"Product Details"} />
      <section className="wpo-shop-single-section section-padding">
        <div className="container">
          {product ? (
            <>
              <Product item={product} addToCart={addToCart} />
              <ProductTabs item={product} />
            </>
          ) : (
            <p>Product not found.</p>
          )}
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.data.products,
  };
};

export default connect(mapStateToProps, { addToCart })(ProductSinglePage);
