import React from "react";
import "./Admin.css";
import UnitedMindchem from "../images/logo-2.png";

const AdminLogin = () => {
  return (
    <>
      <div className="wrapper">
        <div className="logo">
          <img src={UnitedMindchem} alt="Twitter Logo" />
        </div>
        <div className="text-center mt-4 name">United Minechem</div>
        <form className="p-3 mt-3">
          <div className="form-field d-flex align-items-center">
            <i className="far fa-user"></i>
            <input
              type="text"
              name="userName"
              id="userName"
              placeholder="Email or Username"
            />
          </div>
          <div className="form-field d-flex align-items-center">
            <i className="fas fa-key"></i>
            <input
              type="password"
              name="password"
              id="pwd"
              placeholder="Password"
            />
          </div>
          <button className="btn mt-3" type="submit">
            Login
          </button>
        </form>
        <div className="text-center fs-6">
          <a href="#">Forget password?</a>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
