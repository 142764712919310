import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { NavLink } from "react-router-dom";
import "./style.css";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/",
    // submenu: [
    //   {
    //     id: 11,
    //     title: "Home",
    //     link: "/",
    //   },
    //   {
    //     id: 12,
    //     title: "Home style 2",
    //     link: "/home-2",
    //   },
    //   {
    //     id: 13,
    //     title: "Home style 3",
    //     link: "/home-3",
    //   },
    //   {
    //     id: 14,
    //     title: "Home style 4",
    //     link: "/home-4",
    //   },
    // ],
  },

  {
    id: 6,
    title: "About",
    link: "/about",
    // submenu: [
    //   {
    //     id: 61,
    //     title: "Service",
    //     link: "/service",
    //   },
    //   {
    //     id: 62,
    //     title: "Service Single",
    //     link: "/service-single/Civil-Engineering",
    //   },
    // ],
  },
  {
    id: 7,
    title: "Category",
    link: "/#",
    submenu: [
      {
        id: 71,
        title: "Import Product",
        link: "/category/import",
      },
      {
        id: 72,
        title: "Export Product",
        link: "/category/export",
      },
    ],
  },
  {
    id: 3,
    title: "Products",
    link: "/shop",
    // submenu: [
    //   {
    //     id: 31,
    //     title: "About",
    //     link: "/about",
    //   },
    //   {
    //     id: 32,
    //     title: "Shop",
    //     link: "/shop",
    //   },
    //   {
    //     id: 33,
    //     title: "Shop Single",
    //     link: "/product-single/Impact-Drill-Yato-Brand",
    //   },
    //   {
    //     id: 34,
    //     title: "Cart",
    //     link: "/cart",
    //   },
    //   {
    //     id: 35,
    //     title: "Checkout",
    //     link: "/checkout",
    //   },
    //   {
    //     id: 36,
    //     title: "Pricing",
    //     link: "/pricing",
    //   },
    //   {
    //     id: 37,
    //     title: "Team",
    //     link: "/team",
    //   },
    //   {
    //     id: 38,
    //     title: "Team Single",
    //     link: "/team-single/1",
    //   },
    // ],
  },

  //   {
  //     id: 5,
  //     title: "Blog",
  //     link: "/blog",
  //     submenu: [
  //       {
  //         id: 51,
  //         title: "Blog",
  //         link: "/blog",
  //       },
  //       {
  //         id: 52,
  //         title: "Blog Left sidebar",
  //         link: "/blog-left",
  //       },
  //       {
  //         id: 53,
  //         title: "Blog full width",
  //         link: "/blog-fullwidth",
  //       },
  //       {
  //         id: 54,
  //         title: "Blog single",
  //         link: "/blog-single/1",
  //       },
  //       {
  //         id: 55,
  //         title: "Blog single Left sidebar",
  //         link: "/blog-single-left-sidebar/1",
  //       },
  //       {
  //         id: 56,
  //         title: "Blog single Left sidebar",
  //         link: "/blog-single-fullwidth/1",
  //       },
  //     ],
  //   },
  {
    id: 88,
    title: "Contact",
    link: "/contact",
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
    setMenuState(false);
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem
                className={item.id === openId ? "active" : null}
                key={mn}
              >
                {item.submenu ? (
                  <Fragment>
                    <p
                      onClick={() =>
                        setOpenId(item.id === openId ? 0 : item.id)
                      }
                    >
                      {item.title}
                      <i
                        className={
                          item.id === openId
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                      ></i>
                    </p>
                    <Collapse
                      in={item.id === openId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List className="subMenu">
                        <Fragment>
                          {item.submenu.map((submenu, i) => {
                            return (
                              <ListItem key={i}>
                                <NavLink
                                  onClick={ClickHandler}
                                  className="active"
                                  to={submenu.link}
                                >
                                  {submenu.title}
                                </NavLink>
                              </ListItem>
                            );
                          })}
                        </Fragment>
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <NavLink className="active" to={item.link}>
                    {item.title}
                  </NavLink>
                )}
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div
        className="showmenu mobail-menu"
        onClick={() => setMenuState(!menuActive)}
      >
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
