import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import abImg1 from "../../images/about/about-first.jpg";
import abImg2 from "../../images/about/about-middle.jpg";
import abImg3 from "../../images/about/about-last.jpg";
import Signeture from "../../images/signeture.png";
import Shape1 from "../../images/about/about-shape-1.png";
import Shape2 from "../../images/about/about-shape-2.png";

const About = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 order-lg-1 order-2">
            <div className="wpo-about-left">
              <div className="wpo-about-img-1">
                <img src={abImg1} alt="" />
              </div>
              <div className="wpo-about-img-2">
                <img src={abImg2} alt="" />
              </div>
              <div className="wpo-about-img-3">
                <img src={abImg3} alt="" />
              </div>
              <div className="wpo-about-left-text">
                <span>
                  “United Minechem is a pioneer in delivering top-grade chemical
                  solutions, catering to industries worldwide.”
                </span>
                <div className="text-item">
                  <img src={Signeture} alt="" />
                  <i className="fi flaticon-quotation"></i>
                </div>
              </div>
              <div className="shape-1">
                <img src={Shape1} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 order-lg-2 order-1">
            <div className="wpo-about-right">
              <SectionTitle
                subtitle="ABOUT UNITED MINECHEM"
                title="We import and export premium ceramics materials and products."
                paragarap="United Minechem specializes in importing and exporting high-quality ceramic raw materials and products. We leverage our extensive industry knowledge and the capabilities of our sister companies t o deliver
excellence."
              />
              <div className="text">
                <span className="text-1">
                  It has not only withstood the test of centuries but has also
                  evolved in the digital age.
                </span>
                <span className="text-1">
                  It has endured and seamlessly transitioned into the digital
                  era.
                </span>
              </div>
              <Link onClick={ClickHandler} to="/about" className="theme-btn">
                About Us<i className="ti-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-2">
        <img src={Shape2} alt="" />
      </div>
    </section>
  );
};

export default About;
