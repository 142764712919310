import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/hero/hero";
import PartnersSecion from "../../components/PartnersSecion/PartnersSecion";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import CtaSection from "../../components/CtaSection/CtaSection";
import About from "../../components/about/about";
import TeamSection from "../../components/TeamSection/TeamSection";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import TestimonialSection from "../../components/TestimonialSection/TestimonialSection";
import BlogSection from "../../components/BlogSection/BlogSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/logo-2.png";
const HomePage = () => {
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <Hero />
      {/* <PartnersSecion /> */}
      <ServiceSection />
      <CtaSection />
      <About />
      {/* <TeamSection /> */}
      {/* <ProjectSection /> */}
      <TestimonialSection />
      {/* <BlogSection /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
