import React from "react";
import { Link, useLocation } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";

const Header = (props) => {
  const location = useLocation();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const isActive = (path) => (location.pathname === path ? "active" : "");

  return (
    <header id="header" className={props.hclass}>
      <div className="wpo-site-header">
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <MobileMenu />
              </div>
              <div className="col-lg-3 col-md-5 col-6">
                <div className="navbar-header">
                  <Link onClick={ClickHandler} className="navbar-brand" to="/">
                    <img src={props.Logo} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link
                        onClick={ClickHandler}
                        className={isActive("/")}
                        to="/"
                      >
                        Home
                      </Link>
                      {/* <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/">Home style 1</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home-2">Home style 2</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home-3">Home style 3</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home-4">Home style 4</Link></li>
                                            </ul> */}
                    </li>
                    <li className="menu-item-has-children">
                      <Link
                        onClick={ClickHandler}
                        className={isActive("/about")}
                        to="/about"
                      >
                        about
                      </Link>
                      {/* <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/about">
                            about
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/shop">
                            Products
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/product-single/Impact-Drill-Yato-Brand"
                          >
                            Product Details
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/cart">
                            Cart
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/checkout">
                            Checkout
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/pricing">
                            Pricing
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/team">
                            Team
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/team-single/1">
                            Team Single
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/404">
                            404 Error
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="#">
                        Category
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link
                            onClick={ClickHandler}
                            className={isActive("/category")}
                            to="/category/import"
                          >
                            import product
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            className={isActive("/#")}
                            to="/category/export"
                          >
                            Export product
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      {/* <Link
                        onClick={ClickHandler}
                        className={isActive("/project")}
                        to="/project"
                      >
                        Project
                      </Link> */}
                      <Link
                        onClick={ClickHandler}
                        className={isActive("/shop")}
                        to="/shop"
                      >
                        Products
                      </Link>
                      {/* <ul className="sub-menu">
                        <li>
                          <Link onClick={ClickHandler} to="/project">
                            Projects
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/project-single/1">
                            Project details
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    {/* <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="#">
                        Blog
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/blog">Blog right sidebar</Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/blog-left-sidebar">
                            Blogs
                          </Link>
                        </li>
                        <li>
                          <Link onClick={ClickHandler} to="/blog-fullwidth">
                            Blog fullwidth
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link onClick={ClickHandler} to="/">
                            Blog Details
                          </Link>
                          <ul className="sub-menu">
                            <li>
                              <Link onClick={ClickHandler} to="/blog-single/1">
                                Blog details right sidebar
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/blog-single-left-sidebar/1"
                              >
                                Blog details left sidebar
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to="/blog-single-fullwidth/1"
                              >
                                Blog details fullwidth
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link
                        onClick={ClickHandler}
                        className={isActive("/contact")}
                        to="/contact"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-2">
                <div className="header-right">
                  <div className="close-form">
                    <Link className="theme-btn" to="/contact">
                      <i className="fi flaticon-phone-call-1"></i>
                      <span>Contact Us</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
