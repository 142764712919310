import React, { Fragment } from "react";
import Slider from "react-slick";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Projects from "../../api/projects";
import Contact from "../ServiceSinglePage/contact";
import { addToCart } from "../../store/actions/action";
import api from "../../api";

import Simg1 from "../../images/project-single/Our-approach01.jpg";
import Simg2 from "../../images/project-single/Our-approach02.jpg";

import Ssilde1 from "../..//images/project-single/Project-Slide01.jpg";
import Ssilde2 from "../..//images/project-single/Project-Slide02.jpg";
import Logo from "../../images/logo-2.png";
import ShopPage from "../ShopPage";
import ShopProduct from "../../components/ShopProduct";

const ProjectSinglePage = (props) => {
  const productsArray = api();
  const { slug } = useParams();

  const filteredProducts = productsArray.filter(
    (product) => product.slug === slug
  );
  const ProjectSingle = Projects.find((item) => item.slug === slug);

  const addToCartProduct = (product, qty = 1) => {
    addToCart(product, qty);
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle={ProjectSingle.title} pagesub={"Project"} />
      <section className="wpo-project-single-area section-padding pt-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-12">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-item">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <div className="wpo-project-single-title">
                        <h3>{ProjectSingle.title}</h3>
                      </div>
                      <p>{ProjectSingle.description.first}</p>
                      <p>{ProjectSingle.description.second}</p>
                    </div>
                    <div className="col-lg-5">
                      <div className="wpo-project-single-content-des-right">
                        <ul>
                          <li>
                            Location :<span>{ProjectSingle.location}</span>
                          </li>
                          <li>
                            Client :<span>wpOceans</span>
                          </li>
                          <li>
                            Architect :<span>{ProjectSingle.architect}</span>
                          </li>
                          <li>
                            Project Type :<span>{ProjectSingle.type}</span>
                          </li>
                          <li>
                            Duration :<span>{ProjectSingle.duration}</span>
                          </li>
                          <li>
                            Completion :<span>15 Dec 2024</span>
                          </li>
                          <li>
                            Share :<span>Industrial, Business</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 px-0">
                    <ShopProduct
                      addToCartProduct={addToCartProduct}
                      products={filteredProducts}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectSinglePage;

// import React, { Fragment, useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import Navbar from "../../components/Navbar/Navbar";
// import PageTitle from "../../components/pagetitle/PageTitle";
// import Scrollbar from "../../components/scrollbar/scrollbar";
// import Footer from "../../components/footer/Footer";
// import ShopProduct from "../../components/ShopProduct";
// import Logo from "../../images/logo-2.png";
// import api from "../../api"; // Assuming this fetches your products

// const ProjectSinglePage = () => {
//   const [productsArray, setProductsArray] = useState([]);
//   const { category } = useParams();

//   // Fetch products on component mount
//   useEffect(() => {
//     const fetchProducts = async () => {
//       const data = await api(); // Ensure `api` returns an array
//       setProductsArray(data);
//     };

//     fetchProducts();
//   }, []);

//   // Filter products by category
//   const filteredProducts = productsArray.filter(
//     (product) =>
//       product.category &&
//       product.category.toLowerCase() === category.toLowerCase()
//   );

//   const addToCartProduct = (product, qty = 1) => {
//     console.log(`Added ${qty} of ${product.title} to cart`);
//   };

//   return (
//     <Fragment>
//       <Navbar Logo={Logo} />
//       <PageTitle
//         pageTitle={
//           category.charAt(0).toUpperCase() + category.slice(1) + " Products"
//         }
//         pagesub={"Products"}
//       />
//       <section className="wpo-project-single-area section-padding">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-12">
//               {filteredProducts.length > 0 ? (
//                 <ShopProduct
//                   addToCartProduct={addToCartProduct}
//                   products={filteredProducts}
//                 />
//               ) : (
//                 <p>No products available for this category.</p>
//               )}
//             </div>
//           </div>
//         </div>
//       </section>
//       <Footer />
//       <Scrollbar />
//     </Fragment>
//   );
// };

// export default ProjectSinglePage;
