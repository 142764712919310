import timg1 from "../images/team/Team01.jpg";
import timg2 from "../images/team/Team02.jpg";
import timg3 from "../images/team/Team03.jpg";
import timg4 from "../images/team/Team04.jpg";
import timg5 from "../images/team/Team04.jpg";

import simg1 from "../images/team/img-1.jpg";
import simg2 from "../images/team/img-2.jpg";
import simg3 from "../images/team/img-3.jpg";
import simg4 from "../images/team/img-4.jpg";
import simg5 from "../images/team/img-4.jpg";

import himg1 from "../images/slider/hero-team/Team01.jpg";
import himg2 from "../images/slider/hero-team/Team02.jpg";
import himg3 from "../images/slider/hero-team/Team03.jpg";
import himg4 from "../images/slider/hero-team/Team04.jpg";

const Teams = [
  {
    Id: "1",
    img: timg1,
    Timg: simg1,
    himg: himg1,
    title: "Bernard Rodgers",
    slug: "1",
    subtitle: "Civil Engineer",
    service: "Industrial, Mechanical",
    exp: "12 Years",
    add: "Millington, Ave, TN 38053",
    phone: "+00 568 746 987",
    email: "youremail@gmail.com",
    fax: " 568 746 987",
  },
  {
    Id: "2",
    img: timg2,
    Timg: simg2,
    himg: himg2,
    title: "Sebastian Turner",
    slug: "2",
    subtitle: "Civil Engineer",
    service: "Industrial, Mechanical",
    exp: "10 Years",
    add: "Millington, Ave, TN 38053",
    phone: "+00 568 746 987",
    email: "youremail@gmail.com",
    fax: " 568 746 987",
  },
  {
    Id: "3",
    img: timg3,
    Timg: simg3,
    himg: himg3,
    title: "Holden McGroin",
    slug: "3",
    subtitle: "Civil Engineer",
    service: "Industrial, Mechanical",
    exp: "14 Years",
    add: "Millington, Ave, TN 38053",
    phone: "+00 568 746 987",
    email: "youremail@gmail.com",
    fax: " 568 746 987",
  },
  {
    Id: "4",
    img: timg4,
    Timg: simg4,
    himg: himg4,
    title: "Bernard Rodgers",
    slug: "4",
    subtitle: "Civil Engineer",
    service: "Industrial, Mechanical",
    exp: "09 Years",
    add: "Millington, Ave, TN 38053",
    phone: "+00 568 746 987",
    email: "youremail@gmail.com",
    fax: " 568 746 987",
  },
  {
    Id: "5",
    img: timg5,
    Timg: simg5,
    himg: himg1,
    title: "Bernard Rodgers",
    slug: "5",
    subtitle: "Civil Engineer",
    service: "Industrial, Mechanical",
    exp: "09 Years",
    add: "Millington, Ave, TN 38053",
    phone: "+00 568 746 987",
    email: "youremail@gmail.com",
    fax: " 568 746 987",
  },
];

export default Teams;
