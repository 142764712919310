import pimg1 from "../images/project/Project01.jpg";
import pimg2 from "../images/project/Project02.jpg";
import pimg3 from "../images/project/Project03.jpg";
import pimg4 from "../images/project/Project04.jpg";
import pimg5 from "../images/project/Project05.jpg";
import pimg6 from "../images/project/Project06.jpg";
import pimg7 from "../images/project/Project07.jpg";
import pimg8 from "../images/project/Project08.jpg";
import pimg9 from "../images/project/Project09.jpg";

const Projects = [
  {
    id: "1",
    title: "Import Services",
    subtitle: "FACTORY / INDUSTRY",
    slug: "import",
    pimg1: pimg1,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
    description: {
      first: `We import essential ceramic raw materials like Bentonite,
  Alumina Balls, Wollastonite, and Sodium Lignosulphonate trom
  around the world. Our robust supply chain ensures timely
  delivery and efficient logistics management, supporting
  seamless global operations.`,
      second: `Through our extensive global network, we source premium-quality materials to meet diverse industry needs. Our focus on consistency and reliability allows us to maintain strong partnerships with suppliers and clients alike, ensuring excellence in every aspect of the import process.`,
    },
    ourStatagy: {
      para: "We are committed to optimizing our import operations by focusing on quality, efficiency, and strategic partnerships. Our approach ensures seamless supply chain management and exceptional service delivery across global markets.",
      lines: {
        first: "Global Sourcing Expertise",
        second: "Quality Assurance",
        third: "Timely Deliveries",
        fourth: "Sustainability Focus",
        fifth: "Customer-Centric Approach",
      },
    },
    ourApproach:
      "In import services, our approach is rooted in sourcing premium materials from trusted global suppliers while maintaining efficiency and reliability. We emphasize streamlined logistics, rigorous quality control, and sustainability to support seamless operations. Our client-focused strategies ensure exceptional service and long-term partnerships.",
  },
  {
    id: "2",
    title: "Export Services",
    subtitle: "FACTORY / INDUSTRY",
    slug: "export",
    pimg1: pimg2,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
    description: {
      first: `United Minechem's expertise spans across mining
  and exporting Ball Clay, China Clay, Potassium
  Feldspar, Sodium Feldspar & Quartz. We also
  produce and export premium tiles and slabs. Our
  sister companies' state-of-the-art facilities
  ensure top-notch quality and a wide range of
  designs to meet global standards.`,
      second: `Our dedicated team ensures efficient logistics and timely delivery of products worldwide. With a focus on customer satisfaction, we continuously enhance our processes to provide exceptional value, quality, and reliability to our global clientele.`,
    },
    ourStatagy: {
      para: "We are committed to optimizing our import operations by focusing on quality, efficiency, and strategic partnerships. Our approach ensures seamless supply chain management and exceptional service delivery across global markets.",
      lines: {
        first: "Global Sourcing Expertise",
        second: "Quality Assurance",
        third: "Timely Deliveries",
        fourth: "Sustainability Focus",
        fifth: "Customer-Centric Approach",
      },
    },
    ourApproach:
      "Our approach centers on building strong global networks and leveraging advanced logistics to ensure the seamless import of high-quality ceramic raw materials. We prioritize efficiency, transparency, and sustainability to meet the evolving needs of our clients. Through meticulous planning and strategic execution, we deliver excellence in every step of the supply chain.",
  },
  {
    id: "3",
    title: "Gas & Oil Engeneering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "3",
    pimg1: pimg3,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
  },
  {
    id: "4",
    title: "Gas & Oil Engeneering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "4",
    pimg1: pimg4,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
  },
  {
    id: "5",
    title: "Gas & Oil Engeneering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "5",
    pimg1: pimg5,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
  },
  {
    id: "6",
    title: "Gas & Oil Engeneering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "6",
    pimg1: pimg6,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
  },
  {
    id: "7",
    title: "Gas & Oil Engeneering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "7",
    pimg1: pimg7,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
  },
  {
    id: "8",
    title: "Gas & Oil Engeneering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "8",
    pimg1: pimg8,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
  },
  {
    id: "9",
    title: "Gas & Oil Engeneering",
    subtitle: "FACTORY / INDUSTRY",
    slug: "9",
    pimg1: pimg9,
    date: "Feb 2019",
    location: "Austria",
    type: "Civil Engineer",
    service: "Industrial, Mechanical",
    duration: "12 Years",
    add: "Millington, Ave, TN 38053",
    architect: "Don Johnson",
    share: "Industrial, Business",
    fax: " 568 746 987",
  },
];
export default Projects;
