import simg1 from "../images/service/quality-excellence.jpg";
import simg2 from "../images/service/global-expansion.jpg";
import simg3 from "../images/service/customer-satisfication.jpg";
import simg4 from "../images/service/4.jpg";
// import simg5 from '../images/service/5.jpg'
// import simg6 from '../images/service/6.jpg'

import sSimg1 from "../images/service-single/sSimg-added.jpg";
import sSimg2 from "../images/service-single/sSimg-added.jpg";
import sSimg3 from "../images/service-single/sSimg-added.jpg";
import sSimg4 from "../images/service-single/sSimg-added.jpg";
// import sSimg5 from '../images/service-single/img-5.jpg'
// import sSimg6 from '../images/service-single/img-6.jpg'

const Services = [
  {
    Id: "01",
    sImg: simg1,
    sSImg: sSimg1,
    title: "Quality Excellence",
    slug: "Custom-Chemical-Manufacturing",
    description:
      "To consistently deliver high-quality ceramic raw materials and products that meet and exceed international standards.",
    bigDescription1:
      "At United Minechem, we specialize in producing customized chemical solutions tailored to meet the unique requirements of various industries. Whether it's for pharmaceuticals, agriculture, or industrial applications, we ensure every product is crafted with precision. Our state-of-the-art facilities and expert team work closely with clients to create the perfect chemical formulations.",
    bigDescription2:
      "We understand that every industry has specific needs, and we adapt our manufacturing processes to deliver high-quality, cost-effective products. Our commitment to innovation and efficiency ensures that we remain at the forefront of chemical manufacturing. With United Minechem, you get reliable, bespoke solutions that align perfectly with your business goals.",
    ourCapabilities: {
      head: "Our Capabilities",
      para: "We specialize in producing highly tailored chemical solutions designed to meet the unique needs of various industries. Whether for pharmaceuticals, agriculture, or industrial applications, we guarantee precision in every product. Our team works closely with clients to create the ideal chemical formulations.",
      lines: {
        first:
          "High-precision chemical formulations tailored to specific industry needs.",
        second:
          "State-of-the-art production facilities ensuring top-quality results.",
        third:
          "Scalable manufacturing processes to meet both small and large batch requirements.",
        fourth: "Commitment to sustainability in every production step.",
        fifth:
          "Advanced quality control protocols for consistent, reliable products.",
      },
    },
    ourApproach: {
      head: "Our Approach",
      para: "At United Minechem, we take a client-centric approach to chemical manufacturing. Our team works closely with each client to understand their unique requirements and challenges. We use the latest technologies and innovative processes to ensure we deliver high-quality, cost-effective solutions tailored to meet specific industry needs.",
    },
    ourWorkProcess: {
      head: "Our Work Process",
      lines: {
        first:
          "Initial consultation to understand client needs and project requirements.",
        second:
          "Customized formulation and design of chemical products based on industry specifications.",
        third:
          "Utilization of state-of-the-art production facilities for scalable manufacturing.",
        fourth:
          "Continuous quality monitoring and testing to ensure product consistency and compliance.",
      },
    },
  },
  {
    Id: "02",
    sImg: simg2,
    sSImg: sSimg2,
    title: "Global Expansion",
    slug: "Research-and-Development",
    description:
      "To expand our market presence and reach new international markets.",
    bigDescription1:
      "Our Research and Development team is dedicated to pioneering new chemical technologies that push the boundaries of what's possible. We collaborate with clients to create innovative products that meet industry demands while maintaining safety and environmental standards. Every project is treated with the utmost care to develop sustainable and effective chemical solutions.",
    bigDescription2:
      "At United Minechem, our R&D services are focused on creating high-performance chemicals that can be tailored to a wide range of applications. We combine scientific expertise with cutting-edge technology to deliver results that are both impactful and sustainable. Our R&D team works relentlessly to bring ideas to life and provide our clients with a competitive edge in the market.",
    ourCapabilities: {
      head: "Our Capabilities",
      para: "Our R&D team is focused on delivering innovative chemical technologies and solutions. We collaborate with clients to create new products and improve existing ones while ensuring safety and environmental sustainability. Every project is carefully designed to achieve impactful and sustainable results.",
      lines: {
        first:
          "Innovative chemical research to develop cutting-edge solutions.",
        second:
          "Collaboration with clients to design and implement new product formulations.",
        third:
          "Utilization of advanced laboratory facilities and expert scientists.",
        fourth:
          "Focus on creating eco-friendly and sustainable chemical alternatives.",
        fifth:
          "Testing and optimization to ensure product efficacy and compliance.",
      },
    },
    ourApproach: {
      head: "Our Approach",
      para: "We are passionate about innovation and continuously strive to push the boundaries of chemical science. Our R&D team combines deep technical expertise with creative problem-solving to design cutting-edge solutions. We partner with our clients to develop new products that meet evolving market needs while ensuring compliance with industry regulations.",
    },
    ourWorkProcess: {
      head: "Our Work Process",
      lines: {
        first:
          "In-depth analysis of market trends and client-specific challenges to develop innovative solutions.",
        second:
          "Collaborative brainstorming sessions with clients to design and prototype new chemical formulations.",
        third:
          "Implementation of rigorous testing and optimization procedures to ensure functionality and safety.",
        fourth:
          "Feedback loop for continuous improvement, ensuring the final product meets all performance and regulatory standards.",
      },
    },
  },
  {
    Id: "03",
    sImg: simg3,
    sSImg: sSimg3,
    title: "Customer Satisfaction",
    slug: "QC-and-Testing",
    description:
      "To build and maintain strong relationships with our clients by understanding and fulfiling their needs.",
    bigDescription1:
      "Our commitment to quality begins with rigorous testing procedures at every stage of production. We ensure that all chemicals are produced to the highest industry standards, and we conduct thorough inspections to guarantee their purity and efficacy. United Minechem's quality control system is designed to ensure only the best products reach our clients.",
    bigDescription2:
      "We use advanced testing methodologies and cutting-edge equipment to verify that every product meets both regulatory and client-specific standards. Our quality control team is highly trained and dedicated to ensuring consistency and reliability in all chemical products. Trust us to provide superior quality that enhances your business operations.",
    ourCapabilities: {
      head: "Our Capabilities",
      para: "We ensure that every chemical product meets the highest standards of quality. Our testing methods are designed to verify purity, stability, and performance. We use advanced equipment and industry protocols to guarantee that every product is reliable and consistent.",
      lines: {
        first:
          "Comprehensive testing for product purity, stability, and performance.",
        second:
          "Advanced equipment and industry-standard protocols for quality assurance.",
        third:
          "Ongoing monitoring to ensure products meet regulatory standards.",
        fourth:
          "Dedicated team of experts to ensure consistent product quality.",
        fifth:
          "Quick and reliable testing for fast-tracking production timelines.",
      },
    },
    ourApproach: {
      head: "Our Approach",
      para: "Quality is at the heart of everything we do. We rigorously test each product to ensure it meets the highest standards of performance, stability, and purity. Our approach involves continuous monitoring and analysis, using state-of-the-art testing equipment to ensure that every product we deliver is reliable and consistent.",
    },
    ourWorkProcess: {
      head: "Our Work Process",
      lines: {
        first:
          "Detailed product inspections based on industry standards and regulatory guidelines.",
        second:
          "Advanced laboratory testing using cutting-edge equipment to analyze purity, stability, and performance.",
        third:
          "Real-time monitoring throughout production to detect any potential inconsistencies.",
        fourth:
          "Final product testing and reporting to ensure compliance with client specifications and quality standards.",
      },
    },
  },
];

export default Services;
