import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/Services";
import ServiceSidebar from "./sidebar";
import Contact from "./contact";
import simg1 from "../../images/service-single/sSimg02.jpg";
import simg2 from "../../images/service-single/sSimg03.jpg";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo-2.png";
const ServiceSinglePage = (props) => {
  const { slug } = useParams();

  const serviceDetails = Services.find((item) => item.slug === slug);

  const currentService = Services.find((service) => service.slug === slug);
  const relatedServices = Services.filter((service) => service.slug !== slug);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Navbar Logo={Logo} />
      <PageTitle pageTitle={serviceDetails.title} pagesub={"Service"} />
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-main-img">
                    <img src={serviceDetails.sSImg} alt="eimg" />
                  </div>
                  <div className="wpo-service-single-title">
                    <h3>{serviceDetails.title}</h3>
                  </div>
                  <p>{serviceDetails.bigDescription1}</p>
                  <p>{serviceDetails.bigDescription2}</p>
                  <div className="row mt-4">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src={simg1} alt="eimg" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        <img src={simg2} alt="eimg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>{serviceDetails.ourCapabilities.head}</h3>
                  </div>
                  <p>{serviceDetails.ourCapabilities.para}</p>
                  <ul>
                    <li>{serviceDetails.ourCapabilities.lines.first}</li>
                    <li>{serviceDetails.ourCapabilities.lines.second}</li>
                    <li>{serviceDetails.ourCapabilities.lines.third}</li>
                    <li>{serviceDetails.ourCapabilities.lines.fourth}</li>
                    <li>{serviceDetails.ourCapabilities.lines.fifth}</li>
                  </ul>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>{serviceDetails.ourApproach.head}</h3>
                  </div>
                  <p>{serviceDetails.ourApproach.para}</p>
                </div>
                <div className="wpo-service-single-item list-widget">
                  <div className="wpo-service-single-title">
                    <h3>{serviceDetails.ourWorkProcess.head}</h3>
                  </div>
                  <ul>
                    <li>{serviceDetails.ourWorkProcess.lines.first}</li>
                    <li>{serviceDetails.ourWorkProcess.lines.second}</li>
                    <li>{serviceDetails.ourWorkProcess.lines.third}</li>
                    <li>{serviceDetails.ourWorkProcess.lines.fourth}</li>
                  </ul>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-single-title">
                    <h3>Related Service</h3>
                  </div>
                  <div className="wpo-service-area">
                    <div className="row align-items-center">
                      {relatedServices.map((service) => (
                        <div
                          id={service.Id}
                          className="col-lg-4 col-md-6 col-12"
                        >
                          <div className="p-0 wpo-service-item">
                            <img
                              src={service.sImg}
                              alt={service.title}
                              className="w-100 h-100 mb-2"
                            />
                            <h2>{service.title}</h2>
                            <p>{service.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="wpo-service-single-item">
                  <div className="wpo-service-contact-area">
                    <div className="wpo-contact-title">
                      <h2>Have project in mind? Let's discuss</h2>
                      <p>
                        Get in touch with us to see how we can help you with
                        your project
                      </p>
                    </div>
                    <div className="wpo-contact-form-area">
                      <Contact />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ServiceSidebar />
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;
